import { groq } from 'next-sanity'
import { z } from 'zod'

import { SanitySlugSchema } from '@/sanity/queries/components/validationPrimitives'

import { publisher, PublisherDataSchema } from '../../publisher/publisherData'
import { ctaBuilder, CTABuilderSchema } from '../builders/ctaBuilder'
import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from '../portableText/basicContentWithLinks'
import { editorialContent, EditorialContentSchema } from '../portableText/editorialContent'
import { artistContent, ArtistContentSchema } from './artistContent'

export const bookContentFields = groq`
  _type,
  _createdAt,
  slug,
  title,
  subtitle,
  cardViewMedia {
    ${mediaBuilder}
  },
  tagline[] {
    ${basicContent}
  },
  publisher->{
    ${publisher}
  },
  bookCTA {
    primaryCTA {
      ${ctaBuilder}
    },
    secondaryCTA {
      ${ctaBuilder}
    }
  },
  photos[]{
    ${mediaBuilder}
  },
  yearSelection,
  description[]{
    ${editorialContent}
  },
  marketingDescription[] {
    ${basicContent}
  },
  "authors": authors[]-> {
    name,
    bio[] {
      ${basicContentWithLinks}
    },
  },
  "artists": artists[]->{
    ${artistContent}
    artistPage->{
      artistIntro,
    },
  },
  "vendor": shopify->store.vendor,
  "price": shopify->store.variants[0]->.store.price,
  "compareAtPrice": shopify->store.variants[0]->.store.compareAtPrice,
  "isbn": shopify->store.variants[0]->.store.sku,
  "product": shopify->store{
    id,
    gid,
    variants[]->{
      _id,
      store {
        id,
        price,
        inventory {
          isAvailable,
        },
        gid,
      },
    },
  },
`
export const bookContent = groq`
  _type == 'book' => {
    ${bookContentFields}
  },
`

const ProductDataSchema = z.object({
  id: z.number(),
  gid: z.string(),
  variants: z.array(
    z.object({
      _id: z.string(),
      store: z.object({
        id: z.number(),
        price: z.number(),
        inventory: z.object({
          isAvailable: z.boolean(),
        }),
        gid: z.string(),
      }),
    })
  ),
})

export const CTAObjectSchema = z.nullable(
  z.object({
    primaryCTA: z.nullable(CTABuilderSchema),
    secondaryCTA: z.nullable(CTABuilderSchema),
  })
)
export const BookContentSchema = z.object({
  _type: z.literal('book'),
  _createdAt: z.string(),
  title: z.string(),
  subtitle: z.nullable(z.string()),
  cardViewMedia: z.nullable(MediaBuilderSchema),
  tagline: z.nullable(z.array(BasicContentSchema)),
  marketingDescription: z.nullable(z.array(BasicContentSchema)),
  bookCTA: CTAObjectSchema,
  publisher: z.nullable(PublisherDataSchema),
  photos: z.array(MediaBuilderSchema).nullable(),
  yearSelection: z.nullable(z.string()),
  description: z.nullable(z.array(EditorialContentSchema)),
  artists: z.nullable(z.array(ArtistContentSchema)),
  authors: z.nullable(
    z.array(
      z.object({
        name: z.string(),
        bio: z.nullable(z.array(BasicContentWithLinksSchema)),
      })
    )
  ),
  slug: z.nullable(SanitySlugSchema),
  price: z.nullable(z.number()),
  compareAtPrice: z.nullable(z.number()),
  isbn: z.nullable(z.string()),
  vendor: z.nullable(z.string()),
  product: z.nullable(ProductDataSchema),
})

export type BookContentType = z.infer<typeof BookContentSchema>
export type CtaObjectType = z.infer<typeof CTAObjectSchema>
