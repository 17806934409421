import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder } from './builders/ctaBuilder'
import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'
import { articleContent, ArticleContentSchema } from './content/articleContent'
import { artistContent, ArtistContentSchema } from './content/artistContent'
import { artistPageContent, ArtistPageContentSchema } from './content/artistPageContent'
import { artworkContent, ArtworkContentSchema } from './content/artworkContent'
import { bookContent, BookContentSchema } from './content/bookContent'
import {
  EcommArtistBookCardContentSchema,
  ecommArtistBooksCardContent,
} from './content/ecommArtistBookContent'
import {
  ecommCollectCardContent,
  EcommCollectCardContentSchema,
} from './content/ecommCollectContent'
import {
  exceptionalWorksPageContent,
  ExceptionalWorksPageContentSchema,
} from './content/exceptionalWorksPageContent'
import { exhibitionPageContent, ExhibitionPageContentSchema } from './content/exhibitionPageContent'
import { fairPageBuilderContent, FairPageBuilderContentSchema } from './content/fairPageContent'
import { locationContent, LocationContentSchema } from './content/locationContent'
import {
  onlineExhibitionPageContent,
  OnlineExhibitionPageContentSchema,
} from './content/onlineExhibitionPageContent'
import { podcastContent, PodcastContentSchema } from './content/podcastContent'
import { seriesContent } from './content/seriesContent'

export enum DataRecordTypes {
  book = 'book',
  artist = 'artist',
  artistPage = 'artistPage',
  artwork = 'artwork',
  location = 'location',
  podcast = 'podcast',
  article = 'article',
  exhibitionPage = 'exhibitionPage',
  fairPage = 'fairPage',
  series = 'series',
  onlineExhibitionPage = 'onlineExhibitionPage',
  exceptionalWork = 'exceptionalWork',
  collectionPage = 'collectionPage',
  artistECommPage = 'artistECommPage',
}

export type DataRecordTypesNames = keyof typeof DataRecordTypes

export const queryByContentType: Record<DataRecordTypesNames, string> = {
  book: bookContent,
  artist: artistContent,
  artwork: artworkContent,
  location: locationContent,
  podcast: podcastContent,
  article: articleContent,
  exhibitionPage: exhibitionPageContent,
  fairPage: fairPageBuilderContent,
  series: seriesContent,
  artistPage: artistPageContent,
  onlineExhibitionPage: onlineExhibitionPageContent,
  exceptionalWork: exceptionalWorksPageContent,
  collectionPage: ecommCollectCardContent,
  artistECommPage: ecommArtistBooksCardContent,
}

export const fieldsPerType = groq`
  _type,
  ${exhibitionPageContent}
  ${onlineExhibitionPageContent}
  ${exceptionalWorksPageContent}
  ${fairPageBuilderContent}
  ${artistContent}
  ${artistPageContent}
  ${articleContent}
  ${artworkContent}
  ${bookContent}
  ${locationContent}
  ${podcastContent}
  ${seriesContent}
  ${ecommCollectCardContent}
  ${ecommArtistBooksCardContent}
`
export const componentTypesData = groq`
  content[]-> {
   ${fieldsPerType}
  },
`

// TODO: extend all content types with DzHeroRelatedContentSchema fields, remove this enhancement
export const wrappedContentPerMolecule = groq`
  _type == 'dzHero' => {
  content[]{
    title,
    mediaOverride {
      ${mediaBuilder}
    },
    ctaOptions {
      primaryCTA {
        ${ctaBuilder}
      },
      secondaryCTA {
        ${ctaBuilder}
      }
    },
    ...content[0]->{
      ${fieldsPerType}
    }
  }
}
`

export const getHeroContentWrapperFields = (content: string) => groq`
  content[]{
    title,
    mediaOverride {
      ${mediaBuilder}
    },
    ctaOptions {
      primaryCTA {
        ${ctaBuilder}
      },
      secondaryCTA {
        ${ctaBuilder}
      }
    },
    ...content[0]->{
      ${content}
    }
  }
`

const DzHeroRelatedContentSchema = z.object({
  title: z.string().nullish(),
  // TODO: refine type depending on dzHero _type
  ctaOptions: z.any(),
  mediaOverride: MediaBuilderSchema.nullish(),
  enableOverrides: z.boolean().nullish(),
})

export const FieldsPerTypeSchema = z
  .discriminatedUnion('_type', [
    ExceptionalWorksPageContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('exceptionalWork'),
    }),
    OnlineExhibitionPageContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('onlineExhibitionPage'),
    }),
    ExhibitionPageContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('exhibitionPage'),
    }),
    FairPageBuilderContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('fairPage'),
    }),
    ArtistContentSchema.merge(DzHeroRelatedContentSchema).extend({ _type: z.literal('artist') }),
    ArtistPageContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('artistPage'),
    }),
    ArticleContentSchema.merge(DzHeroRelatedContentSchema).extend({ _type: z.literal('article') }),
    ArtworkContentSchema.merge(DzHeroRelatedContentSchema).extend({ _type: z.literal('artwork') }),
    BookContentSchema.merge(DzHeroRelatedContentSchema).extend({ _type: z.literal('book') }),
    LocationContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('location'),
    }),
    PodcastContentSchema.merge(DzHeroRelatedContentSchema).extend({ _type: z.literal('podcast') }),
    EcommCollectCardContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('collectionPage'),
    }),
    EcommArtistBookCardContentSchema.merge(DzHeroRelatedContentSchema).extend({
      _type: z.literal('artistECommPage'),
    }),
  ])
  .superRefine((val, ctx) => {
    if (val._type === 'fairPage') {
      if (val.externalUrlToggle && val.externalUrl == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `If 'externalUrlToggle' is true, 'externalUrl' must be a valid URL`,
        })
      }
      if (!val.externalUrlToggle && !val.slug?.current) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `If 'externalUrlToggle' is false, 'slug' must be defined`,
        })
      }
    }
  })

// content: ComponentTypesDataSchema
export const ComponentTypesDataSchema = z.nullable(z.array(FieldsPerTypeSchema))
export type FieldsPerTypeSchemaType = z.infer<typeof FieldsPerTypeSchema>
