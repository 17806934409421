import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import { SanitySlugSchema } from '../validationPrimitives'

//TODO unify location fields & types
export const fairSimpleFields = groq`
  _id,
  _type,
  title,
  slug,
  summary[] {
    ${basicContent}
  },
  location->,
  externalUrlToggle,
  externalUrl,
  startDate,
  endDate,
  displayDate,
  status,
  eyebrow,
  boothOrStand,
  boothOrStandNumber,
`
export const FairStatusSchema = z.enum(['comingSoon', 'open', 'close'])

export const FairSimpleFieldsSchema = z.object({
  _id: z.string(),
  _type: z.literal('fairPage'),
  title: z.string(),
  slug: z.nullable(SanitySlugSchema),
  location: z.nullable(z.any()),
  summary: z.nullable(z.array(BasicContentSchema)),
  externalUrlToggle: z.boolean(),
  externalUrl: z.nullable(z.string().url()),
  startDate: z.string(),
  endDate: z.string(),
  displayDate: z.nullable(z.string()),
  status: z.nullable(FairStatusSchema),
  eyebrow: z.nullable(z.string().max(100)),
  boothOrStand: z.nullable(z.enum(['booth', 'stand'])),
  boothOrStandNumber: z.nullable(z.string()),
})

export const fairComplexFields = groq`
  heroMedia {
    ${mediaBuilder}
  },
  cardViewMedia {
    ${mediaBuilder}
  }
`

export const fairPageContent = groq`
  _type == 'fairPage' => {
    ${fairSimpleFields}
    heroMedia {
      ${mediaBuilder}
    }
  },
`
export const fairPageBuilderContent = groq`
  _type == 'fairPage' => {
    ${fairSimpleFields}
    heroMedia {
      ${mediaBuilder}
    },
    cardViewMedia {
      ${mediaBuilder}
    }
  },
`

export const FairPageBuilderContentSchema = FairSimpleFieldsSchema.merge(
  z.object({
    heroMedia: z.nullable(MediaBuilderSchema),
    cardViewMedia: z.nullable(MediaBuilderSchema),
  })
)
export const FairPageContentSchema = FairSimpleFieldsSchema.merge(
  z.object({
    heroMedia: z.nullable(MediaBuilderSchema),
  })
)
